import { SET_LANGUAGE } from "../actions/actions";

function languageDataReducer(state = { language: 0 }, action: any) {
  switch (action.type) {
    case SET_LANGUAGE:
      return Object.assign({}, state, {
        language: action.languageId
      });
    default:
      return state;
  }
}

export default languageDataReducer;
