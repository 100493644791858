import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./components/common/redux/store";

import * as serviceWorker from "./serviceWorker";
import Intro from "./components/intro/Intro";
import MainMenu from "./components/main-menu/MainMenu";

import "./styles/index.scss";

interface IntroManagerState {
  skippedIntro: boolean;
}

export default class IntroManager extends Component<{}, IntroManagerState> {
  constructor(props: any) {
    super(props);
    this.state = { skippedIntro: false };

    this.skipIntro = this.skipIntro.bind(this);
  }

  skipIntro() {
    this.setState({ skippedIntro: true });
  }

  render() {
    if (!this.state.skippedIntro) {
      return <Intro handleClick={this.skipIntro} />;
    }

    return <MainMenu />;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <IntroManager />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
