import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import { StoreProps } from "../common/redux/store";
import { setLanguage } from "../common/redux/actions/actions";
import ReactPlayer from "react-player";
import ReactAnime from "react-animejs";
import ReactHowler from "react-howler";
import FadeText from "../common/fade-text/FadeText";
import GameBoard from "../game-board/GameBoard";

import "./SongSelect.scss";

import localization from "../../gamedata/localization";
import { bindActionCreators } from "redux";

// TODO: load assets from server later
import survive from "../../assets/img/characters/paff/songs/survive.png";
import surviveClipPreview from "../../assets/audio/songs/paff/survive-preview.wav";
import surviveClipFull from "../../assets/audio/songs/paff/survive-short.wav";

import resultIntro from "../../assets/audio/songs/result/result-intro.wav";
import resultLoop from "../../assets/audio/songs/result/result-loop.wav";

import startLines from "../../assets/img/ui/song-select/start-lines.png";
import startBtn from "../../assets/img/ui/song-select/btn-start.png";
import startCover1 from "../../assets/img/ui/song-select/start-rotate-1.png";
import startCover2 from "../../assets/img/ui/song-select/start-rotate-2.png";

interface SongSelectProps {
  playBGMovie: boolean;
  bgMovieUrl: string | null;
  characterID: number;
}

interface SongSelectState {
  pauseBGMovie: boolean;
  selectedSong: boolean;
  songName: string;
  startedGame: boolean;
  clearedStartBG: boolean;
  songPath: string;
  gameSongEnded: boolean;
  playedResultSongIntro: boolean;
}

const { Anime } = ReactAnime;
const startGameAnimLength = 1250;

class SongSelect extends Component<SongSelectProps & StoreProps, SongSelectState> {
  constructor(props: any) {
    super(props);

    this.state = {
      pauseBGMovie: false,
      selectedSong: false,
      songName: "",
      startedGame: false,
      clearedStartBG: false,
      songPath: surviveClipPreview,
      gameSongEnded: false,
      playedResultSongIntro: false
    };

    this.audioPlayerRef = React.createRef();
    this.handleSongClick = this.handleSongClick.bind(this);
    this.handleGameStart = this.handleGameStart.bind(this);
    this.handleGameSongEnd = this.handleGameSongEnd.bind(this);
    this.handleResultLoop = this.handleResultLoop.bind(this);
    this.handleReturnToSongSelect = this.handleReturnToSongSelect.bind(this);
  }

  private audioPlayerRef: React.RefObject<HTMLAudioElement>;

  handleSongClick() {
    this.setState({ pauseBGMovie: true, selectedSong: true, songName: "Survive" });
  }

  handleGameStart() {
    this.setState({ startedGame: true, songPath: surviveClipFull, gameSongEnded: false, playedResultSongIntro: false });

    // Remove start game ui elements from DOM after animating away
    setTimeout(() => {
      this.setState({ clearedStartBG: true });
    }, startGameAnimLength + 50);
  }

  handleResultLoop() {
    this.setState({ playedResultSongIntro: true });
  }

  handleGameSongEnd() {
    this.setState({ gameSongEnded: true });
  }

  handleReturnToSongSelect() {
    this.setState({ startedGame: false, selectedSong: false, clearedStartBG: false, pauseBGMovie: false, gameSongEnded: false, songPath: surviveClipPreview });
  }

  renderGameBoard() {
    if (this.state.startedGame && !this.state.clearedStartBG) {
      return (
        <div>
          <Anime
            initial={[
              {
                targets: "#scaleWrapper",
                scale: [1, 5],
                opacity: [1, 0],
                easing: "easeOutExpo",
                duration: startGameAnimLength
              }
            ]}></Anime>
        </div>
      );
    }

    if (this.state.startedGame && this.state.clearedStartBG) {
      return <GameBoard songName={this.state.songName} gameSongEnded={this.state.gameSongEnded} returnToSongSelect={this.handleReturnToSongSelect}></GameBoard>;
    }
  }

  /**
   * Renders the menu that appears when selecting a song.
   */
  renderSongStart() {
    let gameBoardRender = this.renderGameBoard();

    if (this.state.selectedSong && !this.state.clearedStartBG) {
      return (
        <div>
          <div id="songStart">
            <div id="scaleWrapper" className="scaleAnimTarget">
              <div className="start-content">
                <div onClick={this.handleGameStart}>
                  <FadeText
                    id="startText"
                    text={localization[this.props.language].songSelect.start_btn}
                    duration={1000}
                    minOpacity={0.5}
                    startOpacityDuration={1}
                    delay={0}
                  />
                  <img className="start-btn" src={startBtn} draggable="false" alt="" />
                </div>
                <img className="start-lines" src={startLines} draggable="false" alt="" />
                <img className="start-cover-1" src={startCover1} draggable="false" alt="" />
                <img className="start-cover-2" src={startCover2} draggable="false" alt="" />
              </div>
            </div>
          </div>
          <Anime
            initial={[
              {
                targets: ".scaleAnimTarget",
                scale: [5, 1],
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 1250
              }
            ]}></Anime>

          {gameBoardRender}
        </div>
      );
    }

    return gameBoardRender;
  }

  /**
   * Renders the song portraits for the selected character.
   */
  renderSongSelection() {
    switch (this.props.characterID) {
      case 1:
        return (
          <div className={this.props.playBGMovie ? "paff-song" : "hidden"} onClick={this.handleSongClick}>
            <img className={!this.state.pauseBGMovie ? "song-img" : "static-song"} src={survive} alt="" />
          </div>
        );
      default:
        return;
    }
  }

  renderSongAudio() {
    if (this.state.gameSongEnded) {
      return (
        <div>
          <ReactHowler src={resultIntro} playing={true} onEnd={this.handleResultLoop} loop={false} mute={this.state.playedResultSongIntro} preload={true} />
          <ReactHowler src={resultLoop} playing={true} loop={true} mute={!this.state.playedResultSongIntro} preload={true} />
        </div>
      );
    } else {
      if (this.props.playBGMovie || this.state.startedGame) {
        return (
          <audio
            ref={this.audioPlayerRef}
            src={this.state.songPath}
            onEnded={this.handleGameSongEnd}
            loop={!this.state.startedGame}
            autoPlay
            preload={"auto"}
          />
        );
      }
    }
  }

  render() {
    const startBlur = this.state.selectedSong ? "static-song" : "";
    const inactiveBlur = !this.props.playBGMovie && this.props.bgMovieUrl != null ? "dark-blur" : "";

    return (
      <div>
        <div className={startBlur}>
          <div id="characterBG" className={inactiveBlur}>
            <ReactPlayer
              url={this.props.bgMovieUrl !== "" && this.props.bgMovieUrl != null ? require("../../assets/" + this.props.bgMovieUrl) : ""}
              playing={this.props.playBGMovie && !this.state.pauseBGMovie}
              loop={true}
              controls={false}
              muted={true}
              width="auto"
              height="auto"
              config={{
                file: {
                  attributes: {
                    preload: "auto"
                  }
                }
              }}
            />
          </div>

          {this.renderSongSelection()}
        </div>

        {this.renderSongStart()}
        {this.renderSongAudio()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return { language: state.language };
};

const mapDispatchToProps = (dispatch: any) => {
  return { setLanguage: bindActionCreators(setLanguage, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(SongSelect);
