import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import { StoreProps } from "../common/redux/store";
import { setLanguage } from "../common/redux/actions/actions";
import SingleNote from "../common/notes/single/SingleNote";

import "./GameBoard.scss";

import scanLine from "../../assets/img/ui/game/scan-line.png";
import startLines from "../../assets/img/ui/song-select/start-lines.png";
import startBtn from "../../assets/img/ui/song-select/btn-start.png";

import * as songData from "../../gamedata/songdata/paff/survive.json";
import { ScoreEventName } from "../common/notes/score-text/ScoreText";

import localization from "../../gamedata/localization";
import { bindActionCreators } from "redux";

export const GAME_BOARD_ID = "gameBoard";

const gameBoardTopCutoffPercent = 0.1;
const gameBoardBottomCutoffPercent = 0.9;

export interface Note {
  id: number;
  type: string;
  activateTime: number;
  spawnPosition: {
    x: number;
    y: number;
  };
}

interface GameBoardProps {
  songName: string;
  gameSongEnded: boolean;
  returnToSongSelect: () => void;
}

interface GameBoardState {
  elapsedTime: number;
  gamePaused: boolean;
  gameEnded: boolean;
  scanLineYPos: number;
  comboCount: number;
  maxCombo: number;
  perfectGoldCount: number;
  perfectCount: number;
  goodCount: number;
  badCount: number;
  missCount: number;
  totalScore: number;
  finalScore: number;
  tpScore: number;
  refreshedComboDisplay: boolean;
  refreshedScoreDisplay: boolean;
}

class GameBoard extends Component<GameBoardProps & StoreProps, GameBoardState> {
  constructor(props: any) {
    super(props);

    this.state = {
      elapsedTime: 0,
      gamePaused: false,
      gameEnded: false,
      scanLineYPos: 0,
      comboCount: 0,
      maxCombo: 0,
      perfectGoldCount: 0,
      perfectCount: 0,
      goodCount: 0,
      badCount: 0,
      missCount: 0,
      totalScore: 0,
      finalScore: 0,
      tpScore: 0,
      refreshedComboDisplay: false,
      refreshedScoreDisplay: false
    };

    this.boardRef = React.createRef();

    this.getTime = this.getTime.bind(this);
    this.tick = this.tick.bind(this);
    this.initScoreData = this.initScoreData.bind(this);
    this.updateScanLineTargets = this.updateScanLineTargets.bind(this);
    this.updateComboCounter = this.updateComboCounter.bind(this);
    this.calculateFinalScore = this.calculateFinalScore.bind(this);
  }

  private tickID = 0;
  private lastTick = this.getTime();
  private endingGame = false;

  // Board data
  private boardRef: React.RefObject<HTMLDivElement>;
  private boardWidth = 0;
  private boardHeight = 0;

  // Song data
  private noteList: Note[] = [];
  // TODO: Investigate partial rendering of note list for performance improvements
  // private noteRenderList: Note[] = [];
  // private noteRenderWindow: Note[] = [];
  private scanTargetList: Note[] = [];
  // private renderWindowSize = 3;

  // Score data
  private basePointVal = 0;
  private comboPointBonus = 0;

  // Scan line properties
  private scanLineTarget = 0;
  private nextScanTime = 0;
  private scanLineDirection = 1;
  private scanLinePos = 0;
  private scanLineSpeed = 0;

  getTime() {
    return window.performance && window.performance.now ? window.performance.now() : new Date().getTime();
  }

  componentDidMount() {
    this.endingGame = false;

    this.buildNoteList();
    this.initScoreData();
    this.lastTick = this.getTime();
    this.tickID = requestAnimationFrame(this.tick);
  }

  tick(time: number) {
    if (this.state.gameEnded) {
      cancelAnimationFrame(this.tickID);
      return;
    }

    if (!this.state.gamePaused) {
      // NOTE: requestAnimationFrame will continue calculating the deltaTime if the user switches tabs
      // which leads to the delta being huge for 1 frame so clamp the value to cap out at 60fps.
      let deltaTime = Math.min(time - this.lastTick, 16.67);
      this.lastTick = time;

      this.setState({ elapsedTime: this.state.elapsedTime + deltaTime });
      this.animateScanLine(deltaTime);
    }

    requestAnimationFrame(this.tick);
  }

  animateScanLine(deltaTime: number) {
    // If current note expired then get the next scan line target
    this.updateScanLineTargets();

    if (this.scanTargetList.length > 0) {
      this.scanLineTarget = this.scanTargetList[0].spawnPosition.y;
      this.nextScanTime = this.scanTargetList[0].activateTime * 1000;

      let remainingDistance = Math.abs(this.scanLineTarget - this.scanLinePos);
      let ticksBeforeNoteArrival = Math.abs((this.nextScanTime - this.state.elapsedTime) / deltaTime);

      // Don't adjust the speed when close to the target
      if (remainingDistance > 0.001) {
        this.scanLineSpeed = remainingDistance / ticksBeforeNoteArrival;
      }

      if (this.scanLineDirection === 1) {
        this.scanLinePos += this.scanLineSpeed;
      } else {
        this.scanLinePos -= this.scanLineSpeed;
      }

      this.scanLinePos = Math.min(Math.max(this.scanLinePos, gameBoardTopCutoffPercent), gameBoardBottomCutoffPercent);

      if (this.scanLinePos === gameBoardBottomCutoffPercent || this.scanLinePos === gameBoardTopCutoffPercent) {
        this.scanLineDirection *= -1;
      }

      this.setState({ scanLineYPos: this.boardHeight * this.scanLinePos });
    } else {
      this.calculateFinalScore();

      if (!this.endingGame) {
        this.endingGame = true;

        setTimeout(() => {
          this.setState({ gameEnded: true });
        }, 1000);
      }
    }
  }

  buildNoteList() {
    songData.charts[0].pages.forEach((page, pageIndex: number) => {
      page.notes.forEach((note: Note, noteIndex: number) => {
        this.noteList.push(note);
        // TODO: Investigate partial rendering of note list for performance improvements
        // this.noteRenderList.push(note);
        this.scanTargetList.push(note);
      });
    });

    // Only render x notes at a time to improve performance
    // TODO: Investigate partial rendering of note list for performance improvements
    // for (let count = 0; count < this.renderWindowSize; count++) {
    //   this.noteRenderWindow.push(this.noteRenderList[count]);
    //   this.noteRenderList.shift();
    // }
  }

  initScoreData() {
    this.basePointVal = 900000 / this.noteList.length;
    this.comboPointBonus = 100000 / ((this.noteList.length * (this.noteList.length - 1)) / 2);
  }

  calculateFinalScore() {
    let finalScore = 0;

    // Calculate standard score
    if (this.state.badCount === 0 && this.state.missCount === 0) {
      finalScore = 1000000 - this.state.goodCount * 0.3 * this.basePointVal;
    } else {
      finalScore = this.state.totalScore;
    }

    // Calculate Technical Points
    let technicalPoints = this.state.perfectGoldCount * 100 + this.state.perfectCount * 70 + (this.state.goodCount * 30) / this.noteList.length;

    // TODO: figure out proper TP calculation
    technicalPoints = Math.min(technicalPoints, 0);
    finalScore = Math.ceil(finalScore);

    this.setState({ finalScore: finalScore, tpScore: technicalPoints });
  }

  updateScanLineTargets() {
    if (this.scanTargetList.length > 0) {
      let targetTime = this.scanTargetList[0].activateTime * 1000;

      if (this.state.elapsedTime >= targetTime) {
        this.scanTargetList.shift();
      }
    }
  }

  updateComboCounter(scoreEvent: ScoreEventName) {
    // TODO: Investigate partial rendering of note list for performance improvements
    // this.noteRenderWindow.shift();

    // if (this.noteRenderList.length > 0) {
    //   this.noteRenderWindow.push(this.noteRenderList[0]);
    //   this.noteRenderList.shift();
    // }

    let points = 0;
    let newMaxCombo = this.state.comboCount + 1;

    if (newMaxCombo < this.state.maxCombo) {
      newMaxCombo = this.state.maxCombo;
    }

    switch (scoreEvent) {
      case ScoreEventName.PERFECT_GOLD:
        points = Math.ceil(this.basePointVal + (this.state.comboCount - 1) * this.comboPointBonus);
        this.setState({ refreshedComboDisplay: false, refreshedScoreDisplay: false });
        this.setState({
          comboCount: this.state.comboCount + 1,
          perfectGoldCount: this.state.perfectGoldCount + 1,
          totalScore: this.state.totalScore + points,
          maxCombo: newMaxCombo
        });
        this.setState({ refreshedComboDisplay: true, refreshedScoreDisplay: true });
        break;
      case ScoreEventName.PERFECT:
        points = Math.ceil(this.basePointVal + (this.state.comboCount - 1) * this.comboPointBonus);

        this.setState({ refreshedComboDisplay: false, refreshedScoreDisplay: false });
        this.setState({
          comboCount: this.state.comboCount + 1,
          perfectCount: this.state.perfectCount + 1,
          totalScore: this.state.totalScore + points,
          maxCombo: newMaxCombo
        });
        this.setState({ refreshedComboDisplay: true, refreshedScoreDisplay: true });
        break;
      case ScoreEventName.GOOD:
        points = Math.ceil(0.7 * this.basePointVal + (this.state.comboCount - 1) * this.comboPointBonus);

        this.setState({ refreshedComboDisplay: false, refreshedScoreDisplay: false });
        this.setState({
          comboCount: this.state.comboCount + 1,
          goodCount: this.state.goodCount + 1,
          totalScore: this.state.totalScore + points,
          maxCombo: newMaxCombo
        });
        this.setState({ refreshedComboDisplay: true, refreshedScoreDisplay: true });
        break;
      case ScoreEventName.BAD:
        points = Math.ceil(0.3 * this.basePointVal);
        this.setState({ refreshedScoreDisplay: false });
        this.setState({ comboCount: 0, badCount: this.state.badCount + 1, totalScore: this.state.totalScore + points });
        this.setState({ refreshedScoreDisplay: true });
        break;
      case ScoreEventName.MISS:
        this.setState({ comboCount: 0, missCount: this.state.missCount + 1 });
        break;
      default:
        this.setState({ comboCount: 0, missCount: this.state.missCount + 1 });
    }
  }

  renderScanLine() {
    let scanLineClasses = `noselect ${this.state.gameEnded ? "scan-line-shrink" : ""}`;

    return (
      <div id="scanLineAnchor" className={scanLineClasses} style={{ top: this.state.scanLineYPos }}>
        <img id="scanLine" className="noselect" src={scanLine} alt="" />
      </div>
    );
  }

  renderCombo() {
    let comboDisplay, comboAnim;
    if (this.state.comboCount > 1) {
      let comboClass = "";
      if (this.state.goodCount === 0 && this.state.badCount === 0 && this.state.missCount === 0) {
        comboClass = "perfect-text";
      } else if (this.state.badCount === 0 && this.state.missCount === 0) {
        comboClass = "good-text";
      } else {
        comboClass = "intense-text";
      }

      comboDisplay = (
        <span id="comboDisplay" className={comboClass}>
          {this.state.comboCount}
        </span>
      );

      comboAnim = `noselect ${this.state.refreshedComboDisplay ? "rubber-band" : ""}`;

      if (this.state.comboCount === 2) {
        comboAnim += " flicker";
      }
    }

    if (this.props.gameSongEnded) {
      comboAnim = "flicker-disappear";
    }

    return (
      <div id="comboCounter" className={comboAnim}>
        <span id="comboNum">{comboDisplay}</span>
        <span id="comboText">{localization[this.props.language].gameBoard.combo}</span>
      </div>
    );
  }

  renderScore() {
    let scoreClass = `noselect ${this.state.refreshedScoreDisplay ? "rubber-band-light" : ""}`;
    let scoreTextClass = `noselect ${this.props.gameSongEnded ? "letter-tighten" : ""}`;

    return (
      <div id="scoreCounter" className={scoreClass}>
        <span id="score" className={scoreTextClass}>
          {this.state.totalScore}
        </span>
      </div>
    );
  }

  renderGameResults() {
    // NOTE: Hide TP for now until calculation equation is found
    if (this.props.gameSongEnded) {
      return (
        <div id="resultsContainer">
          <div className="song-info drop-in-top">{this.props.songName}</div>
          <div className="result-bg drop-in-center">
            <img className="start-lines" src={startLines} alt="" />
            <img className="start-btn" src={startBtn} alt="" />
          </div>
          <div className="score-data fade-in-slow-delay">
            <div className="score-details">
              <span>{localization[this.props.language].gameBoard.score}</span>
              <span>{this.state.finalScore}</span>
            </div>
            {/* <div className="score-details tp-info">
              <span>TP</span>
              <span>{this.state.tpScore}%</span>
            </div> */}
            <div className="score-details">
              <span>{localization[this.props.language].gameBoard.max_combo}</span>
              <span>{this.state.maxCombo}</span>
            </div>
          </div>
          <div className="note-info drop-in-bottom">
            <div className="note-type">
              <span>PERFECT</span>
              <span>{this.state.perfectGoldCount + this.state.perfectCount}</span>
            </div>
            <div className="note-type">
              <span>GOOD</span>
              <span>{this.state.goodCount}</span>
            </div>
            <div className="note-type">
              <span>BAD</span>
              <span>{this.state.badCount}</span>
            </div>
            <div className="note-type">
              <span>MISS</span>
              <span>{this.state.missCount}</span>
            </div>
          </div>
          <div className="return-btn fade-in-slow-btn-delay" onClick={this.props.returnToSongSelect}>
            {localization[this.props.language].gameBoard.return}
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.boardRef.current) {
      this.boardWidth = this.boardRef.current.clientWidth;
      this.boardHeight = this.boardRef.current.clientHeight;
    }

    // TODO: Investigate partial rendering of note list for performance improvements

    // let noteInstances = this.noteList.map((note) => (
    //   <SingleNote
    //     key={note.id}
    //     elapsedGameTime={this.state.elapsedTime}
    //     activateTime={note.activateTime}
    //     spawnPosition={{ x: this.boardWidth * note.spawnPosition.x, y: this.boardHeight * note.spawnPosition.y }}
    //     updateComboCounter={this.updateComboCounter}
    //   />
    // ));

    return (
      <div ref={this.boardRef} id="gameBoard">
        {this.noteList.map((note, noteIndex) => (
          <SingleNote
            key={noteIndex}
            elapsedGameTime={this.state.elapsedTime}
            activateTime={note.activateTime}
            spawnPosition={{ x: this.boardWidth * note.spawnPosition.x, y: this.boardHeight * note.spawnPosition.y }}
            updateComboCounter={this.updateComboCounter}
          />
        ))}

        {this.renderScanLine()}
        {this.renderCombo()}
        {this.renderScore()}
        {this.renderGameResults()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return { language: state.language };
};

const mapDispatchToProps = (dispatch: any) => {
  return { setLanguage: bindActionCreators(setLanguage, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameBoard);
