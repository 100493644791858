import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import ReactAnime from "react-animejs";
import ScrollContainer from "react-indiana-drag-scroll";

import StoryPortrait from "./story-portrait/StoryPortrait";
import ThemeSelector from "../theme-selector/ThemeSelector";
import SongSelect from "../song-select/SongSelect";

import * as storyData from "../../gamedata/story-select.json";
import "./StorySelect.scss";

interface StorySelectProps {
  themeSelectorRef: React.RefObject<ThemeSelector>;
}

interface StorySelectState {
  activeCharacterID: number;
  activePortraitName: string;
  activeBGMoviePath: string | null;
  playBGMovie: boolean;
  selectedCampaign: boolean;
}

const { Anime } = ReactAnime;

export default class StorySelect extends Component<StorySelectProps, StorySelectState> {
  constructor(props: any) {
    super(props);

    this.state = { activeCharacterID: 0, activePortraitName: "", activeBGMoviePath: null, playBGMovie: false, selectedCampaign: false };

    this.switchBGM = this.switchBGM.bind(this);
    this.activatePortrait = this.activatePortrait.bind(this);
    this.deactivatePortrait = this.deactivatePortrait.bind(this);
    this.selectCampaign = this.selectCampaign.bind(this);
    this.deselectCampaign = this.deselectCampaign.bind(this);
  }

  componentDidMount() {
    this.switchBGM(0);
  }

  switchBGM(bgmID: number, selectedCampaign?: boolean) {
    if (this.props.themeSelectorRef.current) {
      this.props.themeSelectorRef.current?.switchBGMTheme(bgmID, selectedCampaign);
    }
  }

  activatePortrait(characterName: string) {
    storyData.charData.map((char) => {
      if (char.name === characterName) {
        this.setState({ activeCharacterID: char.charID, activePortraitName: characterName, activeBGMoviePath: char.BGMoviePath });
      }

      return null;
    });
  }

  deactivatePortrait() {
    this.setState({ activePortraitName: "", activeBGMoviePath: null });
  }

  selectCampaign() {
    // Mute menu music before opening campaign
    this.switchBGM(-1, true);

    this.setState({ selectedCampaign: true, playBGMovie: true });
  }

  deselectCampaign() {
    this.switchBGM(0, false);

    this.setState({ selectedCampaign: false, playBGMovie: false });
  }

  render() {
    return (
      <div>
        <div className={!this.state.selectedCampaign ? "story-select" : "hidden"}>
          <Container fluid>
            <ScrollContainer className="scroll-container" vertical={false} horizontal={true} hideScrollbars={true} nativeMobileScroll={true}>
              <Row>
                {storyData.charData.map((char, index) => (
                  <div key={index} className="column" onClick={() => this.switchBGM(char.charID)}>
                    <StoryPortrait
                      name={char.name}
                      portraitNarrowPath={require("../../assets/" + char.portraitNarrowPath)}
                      portraitFullPath={require("../../assets/" + char.portraitFullPath)}
                      characterLogo={require("../../assets/" + char.charLogo)}
                      charColor={char.color}
                      activatePortrait={() => this.activatePortrait(char.name)}
                      activatedPortraitName={this.state.activePortraitName}
                      selectCampaign={() => this.selectCampaign()}
                      campaignLocked={char.locked}
                    />
                  </div>
                ))}
              </Row>
            </ScrollContainer>
          </Container>

          <Anime
            initial={[
              {
                targets: ".column",
                translateX: ["100vw", 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 1000,
                delay: (el: any, i: any) => 500 + 30 * i
              }
            ]}></Anime>
        </div>

        <SongSelect playBGMovie={this.state.playBGMovie} bgMovieUrl={this.state.activeBGMoviePath} characterID={this.state.activeCharacterID} />
      </div>
    );
  }
}
