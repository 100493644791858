import React, { Component } from "react";
import { between } from "../../utility/MathUtils";
import { ScoreText, ScoreEventName } from "../score-text/ScoreText";

import tapFX1 from "../../../../assets/audio/fx/notes/tapFX-1.wav";

import * as noteData from "../../../../gamedata/note-data.json";
import "./SingleNote.scss";

interface SingleNoteProps {
  elapsedGameTime: number;
  activateTime: number;
  spawnPosition: {
    x: number;
    y: number;
  };
  updateComboCounter: (scoreEvent: ScoreEventName) => void;
}

interface SingleNoteState {
  spawned: boolean;
  currFrameUrl: string;
  animType: AnimationType;
  activated: boolean;
  perfectThreshold: number;
  goodThreshold: number;
  scoreEventName: string;
  markedForDeletion: boolean;
  noteLocked: boolean;
}

// TODO: move to animUtils
export enum AnimationType {
  Spawn,
  Boom
}

const SingleNoteCleanupTime = 900;
const missDelayTime = 500;

export default class SingleNote extends Component<SingleNoteProps, SingleNoteState> {
  constructor(props: any) {
    super(props);

    this.state = {
      spawned: false,
      currFrameUrl: noteData.notes[0].spawnFrameNamePathFragment + noteData.notes[0].spawnFrameRenderOrder[0] + noteData.spriteExt,
      animType: AnimationType.Spawn,
      activated: false,
      perfectThreshold: 150,
      goodThreshold: 350,
      scoreEventName: "",
      markedForDeletion: false,
      noteLocked: false
    };

    this.audioPlayerRef = React.createRef();
    this.checkNoteActivationStatus = this.checkNoteActivationStatus.bind(this);
    this.animateSpawn = this.animateSpawn.bind(this);
    this.animateBoom = this.animateBoom.bind(this);
    this.activateNote = this.activateNote.bind(this);

    this.missNote = this.missNote.bind(this);
    this.triggerNote = this.triggerNote.bind(this);
  }

  componentDidMount() {
    setInterval(() => {
      if (this.props.elapsedGameTime >= this.spawnTime) {
        this.setState({ spawned: true });
        this.animateSpawn(0);
      }
    }, 100);
  }

  // Note activation properties
  private perfectActivateTime = this.props.activateTime * 1000;
  private activatedNote = false;

  // Spawn animation properties
  private totalFrames = noteData.notes[0].spawnFrameRenderOrder.length;
  private spawnFrameNumber = 1;
  private animationDuration = 1000;
  private timePerSpawnFrame = this.animationDuration / this.totalFrames;
  private spawnTime = this.perfectActivateTime - this.animationDuration;
  private timeWhenLastUpdateSpawn = 0;
  private deltaTimeSpawn = 0;

  // Boom animation properties
  private boomFrames = 10;
  private boomFrameNumber = 0;
  private boomAnimationDuration = 250;
  private timePerBoomFrame = this.boomAnimationDuration / this.boomFrames;
  private timeWhenLastUpdateBoom = 0;
  private deltaTimeBoom = 0;

  private spawnID = 0;
  private boomID = 0;
  private audioPlayerRef: React.RefObject<HTMLAudioElement>;

  private finishedAnimation = false;
  private markedForDeletion = false;

  animateSpawn(startTime: number) {
    if (this.state.activated) {
      cancelAnimationFrame(this.spawnID);
      return;
    }

    if (!this.timeWhenLastUpdateSpawn) this.timeWhenLastUpdateSpawn = startTime;

    this.deltaTimeSpawn = startTime - this.timeWhenLastUpdateSpawn;

    // Animate note spawn
    if (this.deltaTimeSpawn > this.timePerSpawnFrame) {
      this.setState({
        currFrameUrl: noteData.notes[0].spawnFrameNamePathFragment + noteData.notes[0].spawnFrameRenderOrder[this.spawnFrameNumber] + noteData.spriteExt
      });

      if (this.spawnFrameNumber < this.totalFrames - 1) {
        this.spawnFrameNumber++;
      } else {
        cancelAnimationFrame(this.spawnID);
        return;
      }

      this.timeWhenLastUpdateSpawn = startTime;
    }

    // Check note tick
    this.checkNoteActivationStatus();

    this.spawnID = requestAnimationFrame(this.animateSpawn);
  }

  checkNoteActivationStatus() {
    if (!this.activatedNote) {
      if (this.props.elapsedGameTime > this.perfectActivateTime + this.state.perfectThreshold) {
        this.missNote();
      }
    } else {
      this.activateNote();
    }
  }

  animateBoom(startTime: number) {
    if (!this.timeWhenLastUpdateBoom) this.timeWhenLastUpdateBoom = startTime;

    this.deltaTimeBoom = startTime - this.timeWhenLastUpdateBoom;

    // Animate note boom
    if (this.deltaTimeBoom > this.timePerBoomFrame) {
      this.setState({
        currFrameUrl: noteData.notes[0].boomFrameNamePathFragment + noteData.notes[0].boomFrameRenderOrder[this.boomFrameNumber] + noteData.spriteExt
      });

      if (this.boomFrameNumber < this.boomFrames - 1) {
        this.boomFrameNumber++;
      } else {
        cancelAnimationFrame(this.boomID);
        return;
      }

      this.timeWhenLastUpdateBoom = startTime;
    }

    this.boomID = requestAnimationFrame(this.animateBoom);
  }

  triggerNote() {
    if (!this.activatedNote) {
      this.activatedNote = true;
    }
  }

  activateNote() {
    if (!this.finishedAnimation) {
      // console.log(this.props.elapsedGameTime, this.perfectActivateTime);
      let activateTime = this.props.elapsedGameTime;
      let scoreEventName = "";

      if (between(activateTime, this.perfectActivateTime, this.state.perfectThreshold)) {
        console.log("PERFECT");
        scoreEventName = ScoreEventName.PERFECT;
        this.props.updateComboCounter(ScoreEventName.PERFECT);
      } else if (between(activateTime, this.perfectActivateTime, this.state.goodThreshold)) {
        console.log("GOOD");
        scoreEventName = ScoreEventName.GOOD;
        this.props.updateComboCounter(ScoreEventName.GOOD);
      } else {
        console.log("BAD");
        scoreEventName = ScoreEventName.BAD;
        this.props.updateComboCounter(ScoreEventName.BAD);
      }
      this.setState({ activated: true, scoreEventName: scoreEventName });
      this.animateBoom(0);
      this.finishedAnimation = true;
      this.audioPlayerRef.current?.play();
    }
  }

  missNote() {
    if (!this.state.noteLocked) {
      // console.log(this.props.elapsedGameTime, this.perfectActivateTime);
      console.log("MISS");
      this.setState({ noteLocked: true });
      this.finishedAnimation = true;
      this.props.updateComboCounter(ScoreEventName.MISS);
      setTimeout(() => {
        this.activatedNote = true;
        this.setState({ activated: true, scoreEventName: ScoreEventName.MISS });
      }, missDelayTime);
    }
  }

  renderActivateText() {
    if (this.state.scoreEventName !== "") {
      if (!this.markedForDeletion) {
        this.markedForDeletion = true;
        setTimeout(() => {
          this.setState({ markedForDeletion: true });
        }, SingleNoteCleanupTime);
      }
      return <ScoreText scoreEventName={this.state.scoreEventName} />;
    }
  }

  render() {
    if (!this.state.markedForDeletion && this.state.spawned) {
      let classes = `single-animation noselect ${this.finishedAnimation ? "disperse" : ""}`;

      return (
        <div className="single-note-anchor noselect" style={{ left: this.props.spawnPosition.x, top: this.props.spawnPosition.y }}>
          <div className="single-note noselect" onClick={this.triggerNote}>
            <img className={classes} src={require("../../../../assets/" + this.state.currFrameUrl)} alt="" />
            {this.renderActivateText()}
          </div>

          <audio ref={this.audioPlayerRef} src={tapFX1} loop={false} preload="auto" />
        </div>
      );
    }

    return "";
  }
}
