const localization = [
  {
    lang: "en",
    introData: {
      lang_select_header: "language_select",
      lang_dropdown: "Select Language",
      accept_btn: "Accept",
      disclaimer_header: "disclaimer",
      disclaimer:
        "This is an unofficial fan-made project that was created for practice. Cytus 2 and all of its assets are property of Rayark Games. Please support them by downloading Cytus 2 for",
      disclaimer_or: "or",
      agree_btn: "I Agree",
      neko_sue: "please don't sue me",
      click_skip: "Click to skip"
    },
    mainMenuData: {
      click_start: "Click To Start"
    },
    storyPortraitData: {
      play: "PLAY",
      locked: "LOCKED",
      total_best_score: "Total Best Score",
      avg_tp: "Average TP",
      cleared_songs: "Cleared Songs",
      easy: "EASY",
      hard: "HARD",
      chaos: "CHAOS",
      glitch: "GLITCH"
    },
    songSelect: {
      start_btn: "START"
    },
    gameBoard: {
      combo: "COMBO",
      score: "SCORE",
      max_combo: "MAX COMBO",
      return: "RETURN"
    }
  },
  {
    lang: "jp",
    introData: {
      lang_select_header: "言語選択",
      lang_dropdown: "言語を選択する",
      accept_btn: "アクセプト",
      disclaimer_header: "免責聲明",
      disclaimer: "このプロジェクトは非公式。サイタス ツーはレイアークゲームツの資産。サイタス ツーをダウンロードしてください。",
      disclaimer_or: "か",
      agree_btn: "はい",
      neko_sue: "訴えないでください",
      click_skip: "クリックしてスキップ"
    },
    mainMenuData: {
      click_start: "クリックしてスタート"
    },
    storyPortraitData: {
      play: "PLAY",
      locked: "禁止",
      total_best_score: "総合ベストスコア",
      avg_tp: "平均TP",
      cleared_songs: "完成した曲",
      easy: "容易",
      hard: "ハード",
      chaos: "カオス",
      glitch: "グリッチ"
    },
    songSelect: {
      start_btn: "START"
    },
    gameBoard: {
      combo: "コンボ",
      score: "スコア",
      max_combo: "ベストコンボ",
      return: "リターン"
    }
  }
];

export default localization;
