import React from "react";
import ReactAnime from "react-animejs";

import "./FadeText.scss";

const { Anime } = ReactAnime;

interface IProps_FadeText {
  text: string;
  id?: string;
  duration?: number;
  delay?: number;
  startOpacityDuration?: number;
  minOpacity?: number;
  maxOpacity?: number;
}

const FadeText = (props: IProps_FadeText) => {
  const id = props.id;
  const text = props.text;
  const animeTarget = "#" + id;
  const duration = props.duration;
  const delay = props.delay;
  const startOpacityDuration = props.startOpacityDuration;
  const minOpacity = props.minOpacity;
  const maxOpacity = props.maxOpacity;

  return (
    <div className="fade-text">
      <span id={id}>{text}</span>
      <Anime
        initial={[
          {
            targets: ".fade-text",
            opacity: [
              { value: minOpacity || 0, duration: 0, easing: "easeOutExpo" },
              { value: maxOpacity || 1, duration: startOpacityDuration || 1000, delay: delay || 0 }
            ],
            easing: "linear"
          }
        ]}></Anime>
      <Anime
        initial={[
          {
            targets: animeTarget,
            opacity: minOpacity || 0,
            duration: duration || 1250,
            loop: true,
            direction: "alternate",
            easing: "linear"
          }
        ]}></Anime>
    </div>
  );
};

export default FadeText;
