import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import { StoreProps } from "../../common/redux/store";
import { setLanguage } from "../../common/redux/actions/actions";
import ReactAnime from "react-animejs";
import "./StoryPortrait.scss";

import localization from "../../../gamedata/localization";
import { bindActionCreators } from "redux";

import levelIcon from "../../../assets/img/ui/story-portrait/level-icon.png";
import expArrow from "../../../assets/img/ui/story-portrait/exp-arrow.png";

interface StoryPortraitProps {
  name: string;
  portraitNarrowPath: string;
  portraitFullPath: string;
  characterLogo: string;
  charColor: string;
  activatePortrait: () => void;
  activatedPortraitName: string;
  selectCampaign: () => void;
  campaignLocked: boolean;
}

interface StoryPortraitState {
  hover: boolean;
}

const { Anime } = ReactAnime;

class StoryPortrait extends Component<StoryPortraitProps & StoreProps, StoryPortraitState> {
  constructor(props: any) {
    super(props);

    this.state = { hover: false };

    this.toggleHover = this.toggleHover.bind(this);
  }

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  render() {
    let hoverStyle;
    let boxShadowProp = "0 0 15px 0 " + this.props.charColor;
    let expStyle = {
      background: "linear-gradient(90deg, " + this.props.charColor + " 9%, " + this.props.charColor + "70 10%)",
      filter: "contrast(150%) saturate(200%) drop-shadow(1px 1px 2px " + this.props.charColor + ")",
      boxShadow: boxShadowProp + "60"
    };

    if (this.state.hover) {
      hoverStyle = { boxShadow: boxShadowProp };
    } else {
      hoverStyle = { boxShadow: "none" };
    }

    if (this.props.name !== this.props.activatedPortraitName) {
      return (
        <div className="portrait-container" onClick={this.props.activatePortrait}>
          <span className="portrait-gradient"></span>
          <span className="character-name">{this.props.name}</span>
          <span className="character-exp" style={expStyle}></span>
          <span className="character-level">
            <img src={levelIcon} alt="" /> <span>1</span>
          </span>
          <img
            className="portrait-narrow"
            src={this.props.portraitNarrowPath}
            style={hoverStyle}
            onClick={() => this.setState({ hover: false })}
            onMouseEnter={this.toggleHover}
            onMouseLeave={this.toggleHover}
            alt=""
          />
        </div>
      );
    }

    let selectBtn;

    if (!this.props.campaignLocked) {
      selectBtn = (
        <div className="playBtn">
          <span className="play-bg"></span>
          <span className="play-base"></span>
          <span className="play-text" onClick={this.props.selectCampaign}>
            {localization[this.props.language].storyPortraitData.play}
          </span>
        </div>
      );
    } else {
      selectBtn = (
        <div className="playBtn">
          <span className="play-bg move-stripes lock-bg"></span>
          <span className="play-base lock-base"></span>
          <span className="play-text locked">{localization[this.props.language].storyPortraitData.locked}</span>
        </div>
      );
    }

    return (
      <div className="portrait-container-full">
        <div className="character-container">
          <img className="character-logo" src={this.props.characterLogo} alt="" />
          <img className="portrait-full" src={this.props.portraitFullPath} alt="" />
          {selectBtn}
        </div>
        <div className="campaign-details">
          <div className="name">{this.props.name}</div>
          <div className="character-level">
            <div className="player-level">
              <img src={levelIcon} alt="" /> <span>1</span>
            </div>
            <div className="exp-counter">
              <span className="exp">
                Exp <img src={expArrow} alt="" /> 0%
              </span>
              <span className="character-exp" style={expStyle}></span>
            </div>
          </div>
          <div className="score-panel">
            <span className="score-title">{localization[this.props.language].storyPortraitData.total_best_score}</span>
            <span className="total">0</span>
          </div>
          <div className="score-panel">
            <span className="score-title">{localization[this.props.language].storyPortraitData.avg_tp}</span>
            <span className="total">0%</span>
          </div>
          <div className="cleared-panel">
            <span className="score-title">{localization[this.props.language].storyPortraitData.cleared_songs}</span>
            <span className="difficulty">
              <span className="title">{localization[this.props.language].storyPortraitData.easy}</span>
              <span className="total-songs">000/000</span>
            </span>
            <span className="difficulty">
              <span className="title">{localization[this.props.language].storyPortraitData.hard}</span>
              <span className="total-songs">000/000</span>
            </span>
            <span className="difficulty">
              <span className="title">{localization[this.props.language].storyPortraitData.chaos}</span>
              <span className="total-songs">000/000</span>
            </span>
            <span className="difficulty">
              <span className="title">{localization[this.props.language].storyPortraitData.glitch}</span>
              <span className="total-songs">000/000</span>
            </span>
          </div>
        </div>

        <Anime
          initial={[
            {
              targets: ".portrait-full",
              translateY: 12,
              duration: 3000,
              loop: true,
              direction: "alternate",
              easing: "linear",
              delay: 150
            }
          ]}></Anime>

        <Anime
          initial={[
            {
              targets: ".portrait-container-full",
              translateX: [
                { value: 100, duration: 0 },
                { value: 0, duration: 75 }
              ],
              opacity: [
                { value: 0, duration: 0 },
                { value: 1, duration: 300 }
              ],
              loop: false,
              easing: "linear"
            }
          ]}></Anime>
      </div>
    );
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return { language: state.language };
};

const mapDispatchToProps = (dispatch: any) => {
  return { setLanguage: bindActionCreators(setLanguage, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryPortrait);
