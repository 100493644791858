import React, { Component } from "react";
import ReactAnime from "react-animejs";

import "./ScoreText.scss";
import * as noteData from "../../../../gamedata/note-data.json";

export enum ScoreEventName {
  PERFECT_GOLD = "perfect-gold",
  PERFECT = "perfect",
  GOOD = "good",
  BAD = "bad",
  MISS = "miss"
}

export interface ScoreEvent {
  name: string;
  scoreNamePathFragment: string;
  scoreFrameRenderOrder: string[];
}

const { Anime } = ReactAnime;

interface ScoreTextProps {
  scoreEventName: string;
}

interface ScoreTextState {}

/**
 * Renders the PERFECT/GOOD/BAD/MISS text after a note is activated or expires.
 */
export class ScoreText extends Component<ScoreTextProps, ScoreTextState> {
  constructor(props: any) {
    super(props);

    this.animate = this.animate.bind(this);
  }

  componentWillMount() {
    this.scoreEvent = noteData.scoreEvents.filter((event: ScoreEvent) => {
      if (event.name === this.props.scoreEventName) {
        return event;
      }

      return null;
    })[0];

    this.currFrameUrl = this.scoreEvent.scoreNamePathFragment + this.scoreEvent.scoreFrameRenderOrder[0] + noteData.spriteExt;
    this.frames = this.scoreEvent.scoreFrameRenderOrder.length;
    this.timePerFrame = this.animDuration / this.frames;
  }

  componentDidMount() {
    this.rafID = requestAnimationFrame(this.animate);
  }

  private scoreEvent: ScoreEvent | undefined;
  private currFrameUrl = "";
  private frames = 1;
  private timePerFrame = 1;

  private animDuration = 750;
  private frameNumber = 0;
  private timeWhenLastUpdate = 0;
  private deltaTime = 0;
  private rafID = 0;
  private finishedAnimation = false;

  animate(startTime: number) {
    if (!this.timeWhenLastUpdate) this.timeWhenLastUpdate = startTime;

    this.deltaTime = startTime - this.timeWhenLastUpdate;

    if (this.deltaTime > this.timePerFrame && this.scoreEvent) {
      this.currFrameUrl = this.scoreEvent.scoreNamePathFragment + this.scoreEvent.scoreFrameRenderOrder[this.frameNumber] + noteData.spriteExt;

      if (this.frameNumber < this.frames - 1) {
        this.frameNumber++;
      } else {
        this.finishedAnimation = true;
        cancelAnimationFrame(this.rafID);
        return;
      }

      this.timeWhenLastUpdate = startTime;
    }

    this.rafID = requestAnimationFrame(this.animate);
  }

  renderOut() {
    if (this.finishedAnimation) {
      return (
        <Anime
          initial={[
            {
              targets: ".score-text",
              scale: [1, 0.1],
              opacity: [1, 0],
              easing: "easeOutExpo",
              duration: 150
            }
          ]}></Anime>
      );
    }
  }

  render() {
    let classes = "score-event ";

    switch (this.props.scoreEventName) {
      case ScoreEventName.PERFECT:
        classes += "perfect-text";
        break;
      case ScoreEventName.GOOD:
        classes += "good-text";
        break;
      default:
        classes += "intense-text";
    }

    return (
      <div className="score-text-anchor">
        <div className="score-text">{<img className={classes} src={require("../../../../assets/" + this.currFrameUrl)} alt="" />} </div>
        <Anime
          initial={[
            {
              targets: ".score-text",
              scaleX: [0.1, 1],
              opacity: [0, 1],
              easing: "easeOutExpo",
              duration: 750
            }
          ]}></Anime>
        {this.renderOut()}
      </div>
    );
  }
}

export default ScoreText;
