import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import { StoreProps } from "../common/redux/store";
import { setLanguage } from "../common/redux/actions/actions";
import ReactPlayer from "react-player";
import ReactAnime from "react-animejs";
import ThemeSelector from "../theme-selector/ThemeSelector";
import FadeText from "../common/fade-text/FadeText";

import "./MainMenu.scss";
import menuMovie from "../../assets/movie/menu.mp4";
import cytusLogo from "../../assets/img/title/cytus-glow.png";
import StorySelect from "../story-select/StorySelect";

import localization from "../../gamedata/localization";
import { bindActionCreators } from "redux";

interface MainMenuState {
  openedStoryMenu: boolean;
  fadeAnimationCompleted: boolean;
}

const { Anime } = ReactAnime;
const INTRO_RING_DURATION = 5000;
const FADE_ANIM_DURATION = 6000;

class MainMenu extends Component<StoreProps, MainMenuState> {
  constructor(props: any) {
    super(props);

    this.themeSelectorRef = React.createRef();
    this.state = { openedStoryMenu: false, fadeAnimationCompleted: false };
    this.handleMenuBGClick = this.handleMenuBGClick.bind(this);
  }

  private themeSelectorRef: React.RefObject<ThemeSelector>;

  componentDidMount() {
    setTimeout(() => {
      this.setState({ fadeAnimationCompleted: true });
    }, FADE_ANIM_DURATION);
  }

  handleMenuBGClick() {
    if (this.state.fadeAnimationCompleted) {
      this.setState({ openedStoryMenu: true });
    }
  }

  renderThemeSelector() {
    return (
      <div className="bgm-wrapper">
        <ThemeSelector ref={this.themeSelectorRef} />
      </div>
    );
  }

  renderMenuBG() {
    if (!this.state.openedStoryMenu) {
      return (
        <div className="main-menu fade-from-black" onClick={this.handleMenuBGClick}>
          <div className="video-wrapper title">
            <ReactPlayer url={menuMovie} playing={true} loop={true} controls={false} muted={false} width="auto" height="auto" preload="auto" />
          </div>
          <div className="logo-wrapper noselect">
            <img id={"titleLogo"} src={cytusLogo} alt="" />
            <FadeText id="clickToStart" text={localization[this.props.language].mainMenuData.click_start} delay={INTRO_RING_DURATION} />
          </div>
          <Anime
            initial={[
              {
                targets: "#titleLogo",
                opacity: [
                  { value: 0.2, duration: 100, easing: "easeOutExpo" },
                  { value: 1, duration: 4000 }
                ],
                duration: 4000,
                easing: "linear"
              }
            ]}></Anime>
          <Anime
            initial={[
              {
                targets: "#titleLogo",
                scaleX: 0.95,
                duration: 4000,
                loop: true,
                direction: "alternate",
                easing: "linear"
              }
            ]}></Anime>
        </div>
      );
    }

    return (
      <div className="main-menu">
        <StorySelect themeSelectorRef={this.themeSelectorRef} />
      </div>
    );
  }

  render() {
    return (
      <div className="menu-bg noselect">
        {this.renderThemeSelector()}
        {this.renderMenuBG()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return { language: state.language };
};

const mapDispatchToProps = (dispatch: any) => {
  return { setLanguage: bindActionCreators(setLanguage, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
