import React, { Component, RefObject } from "react";
import { connect, RootStateOrAny } from "react-redux";
import ReactPlayer from "react-player";
import ReactTilt from "react-universal-tilt";
import ReactHowler from "react-howler";
import { StoreProps } from "../common/redux/store";
import { setLanguage } from "../common/redux/actions/actions";

import "./Intro.scss";
import introMovie from "../../assets/movie/intro.mp4";
import popupBG from "../../assets/movie/popup-bg.mp4";
import popupWindow from "../../assets/img/ui/popup/popup-window.png";
import nekoDisclaimer from "../../assets/img/ui/popup/neko-disclaimer.png";

import popupSlow from "../../assets/audio/ui/popup-slow.wav";
import popupBGM from "../../assets/audio/ui/popup-bgm.wav";
import uiClick from "../../assets/audio/ui/UI-Click.wav";
import uiAccept from "../../assets/audio/ui/UI-Accept.wav";
import uiTyping from "../../assets/audio/ui/UI-Typing.wav";

import * as preloadData from "../../gamedata/preload-data.json";
import localization from "../../gamedata/localization";
import { bindActionCreators } from "redux";

interface IntroProps {
  handleClick: () => void;
}

interface IntroState {
  showedAudioPopup: boolean;
  chosenLanguage: number;
  closedPopup: boolean;
  acceptedLanguage: boolean;
  preloadedAssets: boolean;
  showSkipText: boolean;
}

const TYPING_ANIM_DURATION_LANGUAGE = 1000;
const TYPING_ANIM_DURATION_DISCLAIMER = 500;
const SKIP_TEXT_DURATION = 7000;
const SHRINK_ANIM_DURATION = 250;

class Intro extends Component<IntroProps & StoreProps, IntroState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showedAudioPopup: false,
      chosenLanguage: -1,
      acceptedLanguage: false,
      closedPopup: false,
      preloadedAssets: false,
      showSkipText: true
    };

    this.dropdownAudioRef = React.createRef();
    this.acceptAudioRef = React.createRef();
    this.typingAudioRef = React.createRef();

    this.acceptAudioPopup = this.acceptAudioPopup.bind(this);
    this.preloadAllGameAssets = this.preloadAllGameAssets.bind(this);
    this.chooseLanguage = this.chooseLanguage.bind(this);
    this.tryFullscreen = this.tryFullscreen.bind(this);
    this.playGame = this.playGame.bind(this);
    this.acceptLanguage = this.acceptLanguage.bind(this);
    this.chooseLanguage = this.chooseLanguage.bind(this);
    this.handleLanguageBlur = this.handleLanguageBlur.bind(this);
  }

  private dropdownAudioRef: RefObject<HTMLAudioElement>;
  private acceptAudioRef: RefObject<HTMLAudioElement>;
  private typingAudioRef: RefObject<HTMLAudioElement>;
  private unmountedIntro = false;

  private preloadAudUrls: any[] = [];

  componentDidMount() {
    this.preloadAllGameAssets();
  }

  componentWillUnmount() {
    this.unmountedIntro = true;
  }

  preloadAllGameAssets() {
    console.log("Pre-loading game assets...");

    // TODO: Clean up and optimize pre-loading
    // Preload UI data
    preloadData.uiPreload.forEach((element) => {
      let img = new Image();
      img.src = require("../../assets/" + element + preloadData.spriteExt);
    });

    // Format audio data so it can be preloaded by Howler
    preloadData.audioPreload.forEach((element) => {
      this.preloadAudUrls.push(require("../../assets/" + element + preloadData.audioExt));
    });

    // Preload note data
    preloadData.notePreload.single.spawnFrames.forEach((frame) => {
      let img = new Image();
      img.src = require("../../assets/" + preloadData.notePreload.single.spawnFrameNamePathFragment + frame + preloadData.spriteExt);
    });

    preloadData.notePreload.single.boomFrames.forEach((frame) => {
      let img = new Image();
      img.src = require("../../assets/" + preloadData.notePreload.single.boomFrameNamePathFragment + frame + preloadData.spriteExt);
    });

    // Preload score data
    preloadData.scorePreload["perfect-gold"].frames.forEach((frame) => {
      let img = new Image();
      img.src = require("../../assets/" + preloadData.scorePreload["perfect-gold"].scoreNamePathFragment + frame + preloadData.spriteExt);
    });

    preloadData.scorePreload["perfect"].frames.forEach((frame) => {
      let img = new Image();
      img.src = require("../../assets/" + preloadData.scorePreload["perfect"].scoreNamePathFragment + frame + preloadData.spriteExt);
    });

    preloadData.scorePreload["good"].frames.forEach((frame) => {
      let img = new Image();
      img.src = require("../../assets/" + preloadData.scorePreload["good"].scoreNamePathFragment + frame + preloadData.spriteExt);
    });

    preloadData.scorePreload["bad"].frames.forEach((frame) => {
      let img = new Image();
      img.src = require("../../assets/" + preloadData.scorePreload["bad"].scoreNamePathFragment + frame + preloadData.spriteExt);
    });

    preloadData.scorePreload["miss"].frames.forEach((frame) => {
      let img = new Image();
      img.src = require("../../assets/" + preloadData.scorePreload["miss"].scoreNamePathFragment + frame + preloadData.spriteExt);
    });

    // Preload character data
    preloadData.characterPreload.narrowBG.forEach((character) => {
      let img = new Image();
      img.src = require("../../assets/" + character + preloadData.spriteExt);
    });

    preloadData.characterPreload.fullBG.forEach((character) => {
      let img = new Image();
      img.src = require("../../assets/" + character + preloadData.spriteExt);
    });

    preloadData.characterPreload.logos.forEach((character) => {
      let img = new Image();
      img.src = require("../../assets/" + character + preloadData.spriteExt);
    });

    // Preload movie data NOTE: movies are large so don't preload for now
    // preloadData.moviePreload.forEach((movie) => {
    //   let vid = document.createElement("video");
    //   vid.src = require("../../assets/" + movie + preloadData.movieExt);
    // });
  }

  tryFullscreen() {
    let elem = document.documentElement;
    elem.requestFullscreen();
  }

  chooseLanguage(languageID: number) {
    this.setState({ chosenLanguage: languageID });
    this.props.setLanguage(languageID);

    // Play hover audio
    if (this.dropdownAudioRef.current) {
      this.dropdownAudioRef.current.play();
    }
  }

  acceptLanguage() {
    // Play accept audio
    if (this.acceptAudioRef.current) {
      this.acceptAudioRef.current.play();
    }

    this.setState({ closedPopup: true });

    // Let popup shrink before removing
    setTimeout(() => {
      this.setState({ acceptedLanguage: true, closedPopup: false });

      if (this.typingAudioRef.current) {
        this.typingAudioRef.current.play();
      }

      setTimeout(() => {
        if (this.typingAudioRef.current) {
          this.typingAudioRef.current.pause();
        }
      }, TYPING_ANIM_DURATION_DISCLAIMER);
    }, 600);
  }

  playGame() {
    this.tryFullscreen();

    // Play accept audio
    if (this.acceptAudioRef.current) {
      this.acceptAudioRef.current.play();
    }

    setTimeout(() => this.setState({ preloadedAssets: true }), SHRINK_ANIM_DURATION);
    setTimeout(() => {
      if (!this.unmountedIntro) {
        this.setState({ showSkipText: false });
      }
    }, SKIP_TEXT_DURATION);
  }

  renderIntroPopups() {
    // Tilt crashes on mobile devices so just remove it on mobile
    let isMobileDevice = typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;

    const tiltSettings = {
      speed: 300,
      scale: 1,
      reverse: false,
      shine: false,
      reset: true
    };

    if (!this.state.acceptedLanguage) {
      // TODO: Load text from localization file
      let dropdownLabel = "Select Language";
      if (this.state.chosenLanguage === 0) {
        dropdownLabel = "English";
      } else if (this.state.chosenLanguage === 1) {
        dropdownLabel = "日本語";
      }

      let acceptClass = `accept-btn ${this.state.chosenLanguage === -1 ? "inactive-btn" : "active-btn"}`;
      let popupAnim = `popup ${this.state.closedPopup ? "shrink" : "grow-slow"}`;

      let languageModalContent;

      if (!isMobileDevice) {
        languageModalContent = (
          <ReactTilt settings={tiltSettings} className="tilt-elem">
            <div className="popup-base noselect">
              <div className={popupAnim}>
                <h2 className="popup-header typing">《{localization[this.props.language].introData.lang_select_header}》</h2>
                <img id="popupWindow" src={popupWindow} alt="" />
                <div className="language-dropdown">
                  <h1>{dropdownLabel}</h1>
                  <ul onMouseLeave={() => this.handleLanguageBlur(-1)}>
                    <li className="language-choice" onClick={() => this.chooseLanguage(0)} onMouseEnter={() => this.handleLanguageBlur(0)}>
                      <div className="color-bar"></div>
                      <span>English</span>
                    </li>
                    <li className="language-choice" onClick={() => this.chooseLanguage(1)} onMouseEnter={() => this.handleLanguageBlur(1)}>
                      <div className="color-bar"></div>
                      <span>日本語</span>
                    </li>
                  </ul>
                </div>
                <div className={acceptClass} onClick={this.acceptLanguage}>
                  {localization[this.props.language].introData.accept_btn}
                </div>
              </div>
            </div>
            <audio src={popupSlow} autoPlay={true} muted={false} loop={false} preload="auto" />
          </ReactTilt>
        );
      } else {
        languageModalContent = (
          <div>
            <div className="popup-base noselect">
              <div className={popupAnim}>
                <h2 className="popup-header typing">《{localization[this.props.language].introData.lang_select_header}》</h2>
                <img id="popupWindow" src={popupWindow} alt="" />
                <div className="language-dropdown">
                  <h1>{dropdownLabel}</h1>
                  <ul onMouseLeave={() => this.handleLanguageBlur(-1)}>
                    <li className="language-choice" onClick={() => this.chooseLanguage(0)} onMouseEnter={() => this.handleLanguageBlur(0)}>
                      <div className="color-bar"></div>
                      <span>English</span>
                    </li>
                    <li className="language-choice" onClick={() => this.chooseLanguage(1)} onMouseEnter={() => this.handleLanguageBlur(1)}>
                      <div className="color-bar"></div>
                      <span>日本語</span>
                    </li>
                  </ul>
                </div>
                <div className={acceptClass} onClick={this.acceptLanguage}>
                  {localization[this.props.language].introData.accept_btn}
                </div>
              </div>
            </div>
            <audio src={popupSlow} autoPlay={true} muted={false} loop={false} preload="auto" />
          </div>
        );
      }

      return languageModalContent;
    }

    let disclaimerContent;

    if (!isMobileDevice) {
      disclaimerContent = (
        <ReactTilt settings={tiltSettings} className="tilt-elem my-tilt">
          <div className="popup-base">
            <div className="popup grow-slow">
              <h2 className="popup-header typing">《{localization[this.props.language].introData.disclaimer_header}》</h2>
              <img id="popupWindow" src={popupWindow} alt="" />
              <div className="disclaimer noselect">
                {localization[this.props.language].introData.disclaimer}{" "}
                <a href="https://apps.apple.com/us/app/cytus-ii/id1290687550" target="_blank" rel="noopener noreferrer">
                  iOS
                </a>{" "}
                {localization[this.props.language].introData.disclaimer_or}{" "}
                <a href="https://play.google.com/store/apps/details?id=com.rayark.cytus2" target="_blank" rel="noopener noreferrer">
                  Android
                </a>
                .
              </div>
              <div className="neko-disclaimer fade-in-slow">
                <img src={nekoDisclaimer} alt="" />
                <span className="disclaimer noselect">{localization[this.props.language].introData.neko_sue}</span>
              </div>
              <div className="accept-btn active-btn" onClick={this.playGame}>
                {localization[this.props.language].introData.agree_btn}
              </div>
            </div>
          </div>
        </ReactTilt>
      );
    } else {
      disclaimerContent = (
        <div className="popup-base">
          <div className="popup grow-slow">
            <h2 className="popup-header typing">《{localization[this.props.language].introData.disclaimer_header}》</h2>
            <img id="popupWindow" src={popupWindow} alt="" />
            <div className="disclaimer noselect">
              {localization[this.props.language].introData.disclaimer}{" "}
              <a href="https://apps.apple.com/us/app/cytus-ii/id1290687550" target="_blank" rel="noopener noreferrer">
                iOS
              </a>{" "}
              {localization[this.props.language].introData.disclaimer_or}{" "}
              <a href="https://play.google.com/store/apps/details?id=com.rayark.cytus2" target="_blank" rel="noopener noreferrer">
                Android
              </a>
              .
            </div>
            <div className="neko-disclaimer fade-in-slow">
              <img src={nekoDisclaimer} alt="" />
              <span className="disclaimer noselect">{localization[this.props.language].introData.neko_sue}</span>
            </div>
            <div className="accept-btn active-btn" onClick={this.playGame}>
              {localization[this.props.language].introData.agree_btn}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="wrapper">
        {disclaimerContent}

        <audio src={popupSlow} autoPlay={true} muted={false} loop={false} preload="auto" />
      </div>
    );
  }

  handleLanguageBlur(languageChoice: number) {
    let choices = document.getElementsByClassName("language-choice") as HTMLCollectionOf<HTMLElement>;

    if (choices) {
      let choiceArray = Array.from(choices);

      choiceArray.forEach((element, index) => {
        if (languageChoice === -1) {
          element.style.webkitFilter = "blur(0)";
        } else {
          if (index !== languageChoice) {
            element.style.webkitFilter = "blur(2px)";
          } else {
            element.style.webkitFilter = "blur(0)";
          }
        }
      });
    }
  }

  acceptAudioPopup() {
    this.setState({ showedAudioPopup: true });

    // Play audio after component re-render
    setTimeout(() => {
      if (this.typingAudioRef.current) {
        this.typingAudioRef.current.play();
      }

      setTimeout(() => {
        if (this.typingAudioRef.current) {
          this.typingAudioRef.current.pause();
          this.typingAudioRef.current.currentTime = 0;
        }
      }, TYPING_ANIM_DURATION_LANGUAGE);
    }, 200);
  }

  render() {
    if (!this.state.showedAudioPopup) {
      return (
        <div className="popup-base audio-popup noselect">
          <div className="popup audio-popup-base">
            <img id="audioPopupBg" src={popupWindow} alt="" />
            <div className="disclaimer audio-disclaimer noselect">
              <span>This application is optimized for Google Chrome.</span>
              <span>このアプリはGoogle Chrome用に最適化されています。</span>
            </div>
            <div className="accept-btn active-btn" onClick={this.acceptAudioPopup}>
              OK
            </div>
          </div>
        </div>
      );
    }

    if (this.state.preloadedAssets) {
      let skipText;

      if (this.state.showSkipText) {
        skipText = <span className="skip-text noselect">{localization[this.props.language].introData.click_skip}</span>;
      }

      return (
        <div className="intro-bg noselect" onClick={this.props.handleClick}>
          <div className="video-wrapper">
            <ReactPlayer
              url={introMovie}
              playing={true}
              loop={false}
              controls={false}
              muted={false}
              onEnded={this.props.handleClick}
              width="auto"
              height="auto"
              preload="auto"
            />
          </div>
          {skipText}
          <ReactHowler src={this.preloadAudUrls} mute={true} playing={false} preload={true} />
        </div>
      );
    }

    return (
      <div className="noselect">
        {this.renderIntroPopups()}
        <audio src={popupBGM} autoPlay={true} muted={false} loop={true} preload="auto" />
        <audio ref={this.typingAudioRef} src={uiTyping} autoPlay={false} muted={false} loop={false} preload="auto" />
        <audio ref={this.dropdownAudioRef} src={uiClick} autoPlay={false} muted={false} loop={false} preload="auto" />
        <audio ref={this.acceptAudioRef} src={uiAccept} autoPlay={false} muted={false} loop={false} preload="auto" />
        <ReactPlayer id="popupBG" url={popupBG} playing={true} loop={true} controls={false} muted={true} width="auto" height="auto" preload="auto" />
      </div>
    );
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return { language: state.language };
};

const mapDispatchToProps = (dispatch: any) => {
  return { setLanguage: bindActionCreators(setLanguage, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
