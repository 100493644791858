import React, { Component } from "react";
import ReactHowler from "react-howler";

import * as storyData from "../../gamedata/story-select.json";

import defaultThemeIntroRing from "../../assets/audio/main-menu/main-menu-intro.wav";
import defaultTheme from "../../assets/audio/main-menu/main-menu.wav";

interface ThemeSelectorProps {}

interface ThemeSelectorState {
  playedMenuIntroRing: boolean;
  selectedTheme: number;
  selectedCampaign: boolean;
}

export default class ThemeSelector extends Component<ThemeSelectorProps, ThemeSelectorState> {
  constructor(props: any) {
    super(props);

    this.state = {
      playedMenuIntroRing: false,
      selectedTheme: -1,
      selectedCampaign: false
    };

    this.audioPlayerRef = React.createRef();
    this.playedIntroRing = this.playedIntroRing.bind(this);
    this.switchBGMTheme = this.switchBGMTheme.bind(this);
  }

  private audioPlayerRef: React.RefObject<ReactHowler>;

  switchBGMTheme(targetTheme: number, selectedCampaign?: boolean) {
    if (selectedCampaign === true) {
      this.setState({ selectedCampaign: true, selectedTheme: -1 });
    } else if (selectedCampaign === false) {
      this.setState({ selectedCampaign: false, selectedTheme: targetTheme });
    } else {
      this.setState({ playedMenuIntroRing: true, selectedTheme: targetTheme });
    }
  }

  playedIntroRing() {
    this.setState({ playedMenuIntroRing: true, selectedTheme: 0 });
  }

  render() {
    if (!this.state.playedMenuIntroRing) {
      return <ReactHowler src={defaultThemeIntroRing} playing={true} onEnd={() => this.playedIntroRing()} loop={false} preload={true} />;
    }

    let howlers = [];
    
    howlers.push(
      <ReactHowler key={0} src={defaultTheme} playing={true} mute={this.state.selectedTheme !== 0 || this.state.selectedCampaign} loop={true} preload={true} />
    );
    storyData.charData.forEach((char, index) => {
      howlers.push(
        <ReactHowler
          key={index + 1}
          src={require("../../assets/" + char.themePath)}
          playing={true}
          mute={char.charID !== this.state.selectedTheme || this.state.selectedCampaign}
          loop={true}
          preload={true}
        />
      );
    });

    return howlers;
  }
}
