export function between(val: number, target: number, activateTimeOffset: number) {
  let lowerBound = target - activateTimeOffset;
  let upperBound = target + activateTimeOffset;

  if (val >= lowerBound && val <= upperBound) {
    return true;
  } else {
    return false;
  }
}
